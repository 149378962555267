"use strict";

window.addEventListener("cf_consent", function (event) {
    document.getElementById("footer").classList.remove('has-cookie-consent-banner');
});

window.addEventListener("cf_consent_loaded", function (event) {
    setTimeout(function () {
        const cookiefirst = window.CookieFirst;
        if (typeof cookiefirst !== "undefined" && cookiefirst.hasConsented === false) {
            document.getElementById("footer").classList.add('has-cookie-consent-banner');
        }
    }, 1000);
});
