"use strict";

const heroteaser = new Swiper('.swiper-heroteaser', {
  loop: false,
  preloadImages: false,
  updateOnWindowResize: true,
  watchSlidesVisibility: true,
  direction: 'horizontal',
  slidesPerView: 'auto',
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 4
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
})

const teaser = new Swiper('.swiper-teaser', {
  loop: false,
  preloadImages: false,
  updateOnWindowResize: true,
  watchSlidesVisibility: true,
  direction: 'horizontal',
  slidesPerView: 1,
  waitForTransition: false,
  autoHeight: false,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 3
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
})

const imageswiper = new Swiper('.swiper-images', {
  loop: true,
  direction: 'horizontal',
  updateOnWindowResize: true,
  watchSlidesVisibility: true,
  slidesPerView: 1,
  preloadImages: false,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 4
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets'
  },
  on: {
    afterInit: function () {
      recalculateLoop()
    }
  }
});

function recalculateLoop() {
  const swipers = document.querySelectorAll('.swiper-container');
  swipers.forEach((swiper) => {
      swiper['swiper'].loopCreate();
  })
}
